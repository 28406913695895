<template>
  <ion-page>
    <div id="dashboard-sub">
      <base-layout>
        <ion-content class="has-header has-bottom-banner" :scroll-x="false" :scroll-y="false">
          <div class="dashboard-sub-background">
            <h1 style="color: #fff; text-align: center" class="ion-padding">Live</h1>
            <div class="dashboard-content"
                 style=" overflow:auto;padding-bottom:70px; height:100%; margin-top:0px;">

              <div class="container mt-3">
                <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 g-1 justify-content-center">
                  <template v-for="(value,key) in roomStreamMapper" :key="key">
                    <div class="card" style="width: 18rem; margin:10px">
                      <!--iframe :src="value.activeSession.stream_link + '?autoplay=0&muted=1'" class="card-img-top"
                              v-if="value.activeSession && value.activeSession.stream_link"></iframe-->
                      <iframe :src="value.streamlink + '?autoplay=0&muted=1'" class="card-img-top"
                              v-if="value.activeSession && value.activeSession.title && value.streamlink && (user && user.typeName && user.typeName.length > 0)"></iframe>
                      <div class="card-body">
                        <h5 class="card-title">{{ value.label }}</h5>
                        <p class="card-subtitle" v-if="value.activeSession && value.activeSession.title">Gerade Live</p>
                        <p class="card-subtitle" v-if="value.activeSession && value.activeSession.title">
                          {{ value.activeSession.title }}</p>
                        <router-link :to="'/app/liveroom/' + key" class="btn btn-small btn-primary"
                                     style="margin-top: 10px; background: #E30513 !important;border:none">Raum betreten
                        </router-link>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </ion-content>
      </base-layout>
    </div>
  </ion-page>
</template>
<script>
import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";

const config = require('@/config');

export default defineComponent({
  name: "SessionDetail",
  data() {
    return {
      item: null,
      loaded: false,
      isLiveSession: false,
      interval: null,
      actualTime: "",
      activeSessionsByRoom: [],

      roomStreamMapper: {},
      streamLink: null,
      roomId: null,
      room: null,
    };
  },
  components: {
    IonContent,

  },
  methods: {
    ...mapActions("sessions", [
      "getSingleSession",
      "getPresentationList",
      "getSpeakersInSession",
      "getSessionPersons",
      "getLocation",
      "getStreamingRooms",
    ]),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    ...mapActions("video", ["stopVideos"]),
    async getServerTime() {
      try {
        let time_url =
            "https://mantel-api.mobile.documedias.systems/v2/timestamp";
        return (await this.axios.get(time_url)).data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },


    async fetchSession(sessid, room) {
      let item = await this.getSingleSession(sessid);
      this.roomStreamMapper[room].activeSession = item;
    },
    joinChannel() {
      this.$socketInt.emit("join", config.conference);
    },
    getActiveSessionsByRoom(id) {
      this.$socketInt.emit("getActiveSessionsByRoom", {
        congressId: config.conference,
        roomId: id,
      });
    },
    async prepareRooms() {
      let locations = await this.getStreamingRooms();
      for (let l = 0; l < locations.length; l++) {
        let location = locations[l];
        this.roomStreamMapper[location.id] = {
          sessions: [],
          activeSession: {},
          streamlink: location.stream_link,
          label: location.name
        }

      }
    }

  },
  computed: {
    ...mapGetters('auth', ['user'])
  },

  async created() {
        await this.prepareRooms();

    this.joinChannel();
    for (const [key, value] of Object.entries(this.roomStreamMapper)) {
      console.log(value)
      this.getActiveSessionsByRoom(key);
    }


    this.$socketInt.on("getActiveSessionsByRoom", res => {
      //console.log(res);
      if (res) {
        if (this.roomStreamMapper[res.room]) {
          this.roomStreamMapper[res.room].sessions = []
          for (let i = 0; i < res.sessions.length; i++) {
            this.roomStreamMapper[res.room].sessions.push(res.sessions[i].sessionId)
          }
          if (this.roomStreamMapper[res.room].sessions.length > 0) {
            let _id = this.roomStreamMapper[res.room].sessions[0];
            this.fetchSession(_id, res.room);
          } else {
            this.roomStreamMapper[res.room].activeSession = null
            console.log('no active')
          }
        }
      } else {
        console.log('no res')
      }
    });
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(to) {
        if (to.name == 'Live Room Overview') {
          this.joinChannel();
          for (const [key, value] of Object.entries(this.roomStreamMapper)) {
            console.log(value)
            this.getActiveSessionsByRoom(key);
          }
        }
      }
    }
  },
});
</script>
